$(function () {
    $('.daterange').daterangepicker({
        autoUpdateInput: false,
        locale: {
            format: 'YYYY/MM/DD',
            "separator": " - ",
            "applyLabel": "適用します",
            "cancelLabel": "クリア",
            "fromLabel": "From",
            "toLabel": "To",
            "customRangeLabel": "Custom",
            "daysOfWeek": [
                '日', '月', '火', '水', '木', '金', '土'
            ],
            "monthNames": [
                '1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'
            ],
            "firstDay": 1
        }
    });

    $('.daterange').on('apply.daterangepicker', function (ev, picker) {
        $(this).val(picker.startDate.format('YYYY/MM/DD') + ' - ' + picker.endDate.format('YYYY/MM/DD'));
    })

    $('.daterange').on('cancel.daterangepicker', function (ev, picker) {
        $(this).val('')
    })

    $(".calender-picker").flatpickr(
        {
            enableTime: false,
            dateFormat: "Y/m/d",
            locale: "ja",
            // time_24hr: true,
            // minDate: "today",
            allowInput: true,
            position: "auto",
            disableMobile: true
        }
    );
});
